import Menu from "@mui/icons-material/Menu"
import { AppBar, Avatar, Badge, Box, Button, CircularProgress, Popover, Typography, useMediaQuery } from "@mui/material"
import IconWithToolTip from "components/IconWithToolTip"
import { useGetRequest } from "components/useNetworkRequest"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, useLocation, useNavigate } from "react-router-dom"
import { setMenuOpen } from "store/Session"
import { logout, setUserDetails } from "store/user"
import { currencyFormat } from "utils/formatters"
import { styled } from '@mui/material/styles'
import HeaderDrawer from "./HeaderDrawer"
const Chat = React.lazy(() => import('pages/chat'))
const Deposit = React.lazy(() => import('pages/deposit'))
const Account = React.lazy(() => import('pages/account'))
const Subscription = React.lazy(() => import('pages/subscription'))
const Transaction = React.lazy(() => import('pages/transaction'))

const routes = [
  {
    label: "Chat",
    path: "/chat",
    component: Chat,
    render: (user, label) => <Badge badgeContent={user?.hasUnreadMessage === true ? 1 : 0} color="secondary" variant='dot'> {label} </Badge>
  },
  {
    label: "Deposit",
    path: "/deposit",
    component: Deposit
  },
  {
    label: "My Profile",
    path: "/account",
    component: Account
  },
  {
    label: "Transaction",
    path: "/transaction",
    component: Transaction
  },
  {
    label: "Subscription",
    path: "/subscription",
    component: Subscription
  },
]

function headerRoutes() {
  let domRoutes = []
  
  routes.forEach(route => {
    if(route.component) {
      domRoutes.push(<Route  path={route.path} key={route.path} Component={route.component}/>)
    }
  })
  return domRoutes
}

export  {headerRoutes}

const StyledButton = styled(Button)(({theme}) => ({
  fontWeight: 600,
  padding: "10px 20px", 
  background: 'white', 
  color: '#292F42',
  "&:hover": {
    background: 'white', 
    color: '#292F42',
  }
}))

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [headerOpen, setHeaderOpen] = useState(false);

  const handleClick = (event) => {
    if (window?.innerWidth > 900) {
      setAnchorEl(event.currentTarget);
    } else {
      setHeaderOpen(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const currentPath = location?.pathname;
  const desktop = useMediaQuery(theme => theme.breakpoints.up('md'))
  const {t} = useTranslation()
  const user = useSelector(state => state.user.details)

  const {response} = useGetRequest({endPoint: '/v1/users', sendOnAttach: true})

  React.useEffect(() => {
    if(response) {
      dispatch(setUserDetails({user: response}))
    }
  }, [response])


  return <AppBar sx={{
      boxShadow: '0px 0px 20px 0px #00000017',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      padding: { sm: '10px 20px', xs: '6px', md: '12px 40px'},
      margin: { xs: '6px', md: '20px'},
      borderRadius: '16px',
      width: 'auto',
      background: 'white'
    }} position="sticky">
    <Typography>
      {!desktop && <IconWithToolTip title={'Open Menu'} onClick={() => dispatch(setMenuOpen(true))} icon={<Menu />}/>}
    </Typography>
    <Box sx={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between", color: "#292F42"}}>
      <Typography variant="body1" sx={{display: 'flex', mr:2, alignItems: 'flex-end', fontWeight: 600}}> {t("currency")} &nbsp;{user ? currencyFormat(user.points) : <CircularProgress color="secondary" size={24}/>} </Typography>
      <Box sx={{ display: { xs: "none", sm: 'none', md: 'block'}}}>
        {
          routes.map(route => <Button key={route.path} onClick={() => navigate(route.path)} sx={{mr: 2, fontWeight: 600, color: currentPath === route.path ? "#6366F1" : "#292F42" }}> 
            {route.render ? route.render(user, t(route.label)): t(route.label)}
          </Button>)
        }
      </Box>
      <Button aria-describedby={id} sx={{ p: 0 }} onClick={handleClick}>
        <Avatar sx={{ background: 'transparent', border: '1px solid #000000', color: 'black'}} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledButton onClick={() => dispatch(logout())}> {t("Logout")} </StyledButton>
      </Popover>
    </Box>
    <HeaderDrawer setHeaderOpen={setHeaderOpen} headerOpen={headerOpen} />
  </AppBar>
}