import React, { lazy } from 'react'
import { Box } from "@mui/material"
import {Routes, Route} from 'react-router-dom'
import SideDrawer from "./navigation/SideDrawer"
import Header, {headerRoutes} from "./navigation/Header"
import Login from "pages/auth/login"
import ProtectedRoute from "components/ProtectedRoute"
import {getDomRoutes} from "navigation/routes"
import Loading from 'components/Loading'
import Logout from 'pages/account/Logout'
import PageNotFound from 'pages/404'
import Register from 'pages/auth/Register'
const Home = lazy(() => import('pages/home'))

const drawerWidth = 320

function ProtectedNav() {



  return <ProtectedRoute>
    <Box sx={{display: 'flex'}}>
      <SideDrawer />
      <Box sx={{width: {xs: '100vw', sm: '100vw', lg: `calc(100vw - ${drawerWidth}px)`}, height: '100vh', overflow: 'auto'}}>
        <Header />
        <React.Suspense fallback={<Loading />}>
          <Routes>
            {getDomRoutes()}
            {headerRoutes()}
            <Route path={'/logout'} Component={Logout} />
            <Route path='/' Component={Home} />
            <Route path={'/*'} Component={PageNotFound}/>
          </Routes>
        </React.Suspense>
      </Box>
    </Box>
  </ProtectedRoute>
}

function Layout() {
  return <Routes>
    <Route path="/login" Component={Login} />
    <Route path={'/register'} Component={Register} />
    <Route path='/*' Component={ProtectedNav} />
  </Routes>
}

export default Layout