import app from './reducers'
import {configureStore} from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const persistConfig = {
    key: 'naver',
    version: 1,
    storage,
    blacklist: ['session']
}

const persistedReducer = persistReducer(persistConfig, app)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleWare => {
        const middleware = getDefaultMiddleWare({
            serializableCheck: {
              ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
    
        return middleware
    },
    devTools: process.env.NODE_ENV !== 'production'
})


export const persist = persistStore(store);

export default store