import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export default function Loading(props){

  return (
    <Box sx={{
      minHeight: 200,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'background.paper'
    }}>
      {props.loadingMessage}
      <CircularProgress sx={{ color: 'background.purple' }} /> 
    </Box>
  )
}

Loading.defaultProps = {
  isLoading: true,
  loadingMessage: 'Fetching data'
}

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  loadingMessage: PropTypes.string,
}