import { createTheme } from '@mui/material'
import typography from './typography'

const darkTheme = createTheme({
  palette: {
    "mode": "dark",
    "primary": {
      "main": "#6366F1"
    },
    "secondary": {
      "main": "#9c27b0"
    },
    "error": {
      "main": "#F04438"
    },
    "warning": {
      "main": "#F79009"
    },
    "info": {
      "main": "#06AED4",
    },
    "success": {
      "main": "#10B981",
    },
    "text": {
      "primary": "#EDF2F7",
      "secondary": "#A0AEC0",
      "disabled": "rgba(255, 255, 255, 0.48)"
    },
    "divider": "#2D3748",
    "background": {
      "paper": "#111927",
      "default": "#0E1320"
    },
    "action": {
      "active": "#6C737F",
      "hover": "rgba(243, 244, 246, 0.04)",
      "hoverOpacity": 0.04,
      "selected": "rgba(243, 244, 246, 0.12)",
      "selectedOpacity": 0.08,
      "disabled": "rgba(243, 244, 246, 0.38)",
      "disabledBackground": "rgba(243, 244, 246, 0.12)",
      "disabledOpacity": 0.38,
      "focus": "rgba(243, 244, 246, 0.16)",
      "focusOpacity": 0.12,
      "activatedOpacity": 0.12
    },
  }
})

const lightTheme = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // color: 'white',
          // background: '#6366F1',
          "&.Mui-disabled": {
            color: "#757575",
            // background: '#6366F1',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            color: "#111927",
            "&:hover": {
              color: "#111927",
            },
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 24
      }
    },
  },
  "palette": {
    "mode": "light",
    "primary": {
      "main": "#292F42"
    },
    "secondary": {
      "main": "#9c27b0"
    },
    "error": {
      "main": "#F04438"
    },
    "warning": {
      "main": "#F79009"
    },
    "info": {
      "main": "#06AED4",
    },
    "success": {
      "main": "#10B981",
    },
    "text": {
      "primary": "#EDF2F7",
      "secondary": "#A0AEC0",
    },
    "divider": "#2D3748",
    "background": {
      "paper": "#111927",
      "purple": "#6366F1",
      "grey": "#667085",
      "default": "white"
    },
    "action": {
      "active": "#6C737F",
      "hover": "rgba(243, 244, 246, 0.04)",
      "hoverOpacity": 0.04,
      "selected": "rgba(243, 244, 246, 0.12)",
      "selectedOpacity": 0.08,
      "disabledBackground": "rgba(243, 244, 246, 0.12)",
      "disabledOpacity": 0.38,
      "focus": "rgba(243, 244, 246, 0.16)",
      "focusOpacity": 0.12,
      "activatedOpacity": 0.12
    },
  },
  "shape": {
    "borderRadius": 8
  },
  typography,
  "mixins": {
    "toolbar": {
      "minHeight": 56,
      "@media (min-width:0px)": {
        "@media (orientation: landscape)": {
          "minHeight": 48
        }
      },
      "@media (min-width:600px)": {
        "minHeight": 64
      }
    }
  }
}

export { lightTheme, darkTheme };