import { Box, Drawer, Grid, List, ListItemButton, ListItemSecondaryAction, ListItemText, ThemeProvider, createTheme } from "@mui/material"
import { purple } from "@mui/material/colors"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { logout } from "store/user"

const theme = createTheme({
  palette: {
    mode: 'dark', 
    secondary: purple}, 
    typography: {fontFamily: [
      'Inter',
      'Montserrat',
      'sans-serif',
    ].join(',')
  }
})

const drawerWidth = 320

const StyledGrid = styled(Grid)(() => ({
  backgroundColor: '#120023',
  height: '100vh',
  position: 'relative',
  overflow: 'auto',
  color: '#B2B2B2',
  padding: '20px',
  width: drawerWidth,
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}))

const StyledListItemText = styled(ListItemText)(({theme}) => ({
  paddingLeft: 16,
  color: theme.palette.text.secondary,
  fontSize: 16,
  lineHeight: 2
}))

const StyledListButton = styled(ListItemButton)(({theme, special}) => {

  const defaultStyle = {
    '&.Mui-selected': {
      background: "#FAFFFF",
      fontWeight: '600',
      borderRadius: '10px',
      '& svg, & div': {
        color: "#6366F1",
      }
    },
    '&:hover': {
      borderRadius: "10px"
    },
  }

  if(!special){
    return (defaultStyle)
  }

  return {
    ...defaultStyle,
    paddingLeft: 50,
    '&.MuiListItemButton-root': {
      position: 'relative',
      overflow: 'hidden',
      zIndex: 1,
      '&::before': {
        content: "' '",
        position: 'absolute',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, .5) 55%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 75%, rgba(255, 255, 255, .5) 75%)',
        height: '100%',
        width: 40,
        left: 0,
        transform: 'skew(-45deg)',
        animationName: 'slide',
        animationDuration: '3s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear'
      }
    }
  }

});

const headerItems = [
  {
    label: "Chat",
    href: "/chat",
  },
  {
    label: "Deposit",
    href: "/deposit",
  },
  {
    label: "My Profile",
    href: "/account",
  },
  {
    label: "Transaction",
    href: "/transaction",
  },
  {
    label: "Subscription",
    href: "/subscription",
  },
]

export default function HeaderDrawer({ headerOpen, setHeaderOpen }) {

  const dispatch = useDispatch();
  const {t} = useTranslation()

  return <ThemeProvider theme={theme}>
    <Box sx={{ flexShrink: { md: 0 }}}>
      <Drawer anchor="right"
        open={headerOpen}
        ModalProps={{
          keepMounted: true,
        }}
        onClose={() => setHeaderOpen(false)}
      >
        <StyledGrid>
          <List
            sx={{ paddingBottom: 0 }}
          >
            {headerItems.map(item => (
              <StyledListButton key={item.href} to={item.href} component={Link} selected={window.location.pathname.indexOf(item.href) !== -1}>
                <StyledListItemText disableTypography> {t(item.label)} </StyledListItemText>
                <ListItemSecondaryAction> {item.secondaryIcon} </ListItemSecondaryAction>
              </StyledListButton>))}
              <StyledListButton >
                <StyledListItemText disableTypography onClick={() => dispatch(logout())}> {t("Logout")} </StyledListItemText>
              </StyledListButton>
          </List>
        </StyledGrid>
      </Drawer>
    </Box>
  </ThemeProvider>
}